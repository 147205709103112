// Customize default style definitions of Bootstrap.
@import 'colors';

$link-decoration: underline;

$card-bg: $gray-100;

$card-border-width: 0;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1360px
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px
);

:root {
  --bs-secondary-color:  #{$gray-900};
  --bs-tertiary-bg:  #{$gray-100};
}
