@media (prefers-color-scheme: dark) {

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * Bootstrap-Dark (https://vinorodrigues.github.io/bootstrap-dark/)
 * Copyright 2020 Vino Rodrigues
 * This version is a dual color theme, with the default light mode and the dark
 * elements optioned in by a `prefers-color-scheme: dark` media query
 */

  @import "./bootstrap-dark/functions";
  @import "./bootstrap-dark/variables-alt";
  @import "./bootstrap-dark/buttons";
  @import "./bootstrap-dark/modal";
  @import "./bootstrap-dark/card";

    :root {
        --bs-secondary-color: #ddd;
        --bs-tertiary-bg: #223; // .background-gray-100
        scrollbar-color: #333 #000;
    }

    body {
        color: #ddd;
        background-color: #111;
    }

    .content h1,
    .content h2,
    .content h3,
    .content h4,
    .content h5,
    .content h6 {
        color: #ddd;
    }

    p {
      color: $gray-300;
    }

    a {
      color: $teal-200;

      &:hover {
        color: $teal-300;
      }
    }

    .nav-item:hover {
        background: #666;
    }

    .nav-item.active {
        background-color: #444;
    }

    .navbar-collapse > .navbar-nav > .nav-item > .nav-link {
        color: #bbb;
    }

    .navbar-collapse > .navbar-nav > .nav-item > .nav-link:hover {
        color: #fff;
    }

    .dropdown-toggle:after {
        filter: invert(0.8);
    }

    .navbar-toggler {
        filter: invert(1);
    }

    img.phone,
    img.half-phone {
        border-color: #333;
    }

    .post-content, .modal-body {
        & p, li {
          color: #ddd;
        }

        & #donate {
          color: $gray-500;
        }

    }

    .card {
        background-color: $black;
    }

    hr {
        border: none;
    }

    #search-input {
        color: #fff;
    }

    // Footer

    .copyright {
      color: #bbb !important;

      & a {
        color: #bbb !important;
      }

      & a:hover {
        color: #fff !important;
      }
    }

    .overlay {
        background-color: #333;
    }

    .overlay a {
        color: #bbb;
    }

    .overlay a:hover {
       color: #000 !important;
    }

    .background-gray-100 {
        background-color: #223 !important;
    }

    .color-gray-800,
    .color-gray-900 {
        color: #ddd !important;
    }

    .modal-footer {
        border-top: 0;
    }

    .alert-warning {
        background-color: #fff3cd0a;
    }

    .lead {
        background-color: #223;
    }

}
